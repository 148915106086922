<template>
  <!-- <b-container> -->
    <div style="position: relative; height: 100%;">
      <div class="w-100" style="height: 100%;">
        <b-img src="../../../assets/images/tv/screen.png" class="w-100 h-100"/>
      </div>
      <div style="position: absolute; top: 10px;bottom: 10px;" class="w-100">
        <b-img src="../../../assets/images/tv/layout.png" style="height: 100%;" class="w-100 px-1 pt-50 " />
      </div>
      <div style="position: absolute; bottom: 0px;">
        <div class="w-100 p-50 d-flex align-items-center" >
          <VuexyLogo style="width: 65px; height: 65px;z-index: 2;  box-shadow: 0px 1.09194px 10.27583px rgba(0, 0, 0, 0.56);" />
          <div class="footer-video pr-50 pt-50 pb-50 bg-white rounded-sm down-tv" style="background: #EDEDED; overflow: hidden; height: 38px;"> <h4 >
            ورشة العمل التنموية الخاصة / القاعة A1
          </h4></div>
        </div>
      </div>

    </div>
  <!-- </b-container> -->
</template>
<script>
import VuexyLogo from "@core/layouts/components/Logo.vue";

import { BContainer, BRow, BCol, BImg, BCard } from "bootstrap-vue";
import gsap from "gsap"
export default {
  components: {
    VuexyLogo,
    BContainer,
    BRow,
    BCol,
    BImg,
    BCard,
    
  },
  methods: {
    addAnimationsFooter() {
      var t1=gsap.timeline();
        t1.to(".footer-video",{ width: "0%",duration: 1.7 ,opacity:0 ,ease: 'expo' })
        t1.to(".footer-video",{ width: "95%",duration: 1.7 ,opacity:1 ,ease: 'expo' })
    },
  },
  mounted() {
    setInterval(() => {
      this.addAnimationsFooter();
    }, 10000);
  
  }
};
</script>

<style lang="scss">
.video-tv {
  .background-screen {
    // width: 210px;
    height: 500px;
    position: absolute;
    width: 90%;
    background-image: url("../../../assets/images/tv/tvdefault.png");
    background-size: contain;
    background-repeat: no-repeat;
  }

  .down-tv {
    height: 24px;
    margin-left: -5px;
    padding-left: 6px;
  }

  .footer-video{
    overflow-x: hidden;
    width: 90%;
  }

}
</style>
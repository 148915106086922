<template>
  <b-card no-body align="center" class="card_event_tv shadow-sm cursor-pointer">
    <b-img class="img_tv_event" height="90" :src="events.images[0].url" />
    <b-card no-body bg-variant="primary" class="m-0 pt-1" style="border-top-left-radius:0px; border-top-right-radius: 0px;">
      <h5 class="text-center  text-weight"> {{ events.title }} </h5>
      <h5 class="text-center text-weight d-flex align-content-center justify-content-center">{{
        events.starting_date
      }}<feather-icon class="ml-50" icon="CalendarIcon" /></h5>
    </b-card>
  </b-card>
</template>

<script>
import { BCard, BImg } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BImg,
  },
  props: {
    events: {
      type: Object,
      default: () => { }
    }
  },


};
</script>

<style lang="scss">
.card_event_tv {
  .img_tv_event {
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
  }
}
</style>
